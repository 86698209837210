<template>
  <div>
    <search-agency @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
      @change="sortChange"
      row-key="id"
    >
      <div slot="name" slot-scope="name, record">
        <div v-if="isShowMenu('agents/show')">
          <router-link :to="'/agents/' + record.id + '/info'"> {{ name }} </router-link>
        </div>
        <div v-else>{{ name }}</div>
      </div>

      <div slot="is_api_enabled_display" slot-scope="text, record">
        <div>
          <a @click="handleShowApiDetail(record.id)">{{ text }}</a>
        </div>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <edit-api-info
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="this.editingId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import { findAgencies, exportAgencies } from '@/api/agent'
import { hasPermission } from '@/utils/permission'
import SearchAgency from '@/views/agents/agencies/Search'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AgentAgenciesList',
  components: {
    Pagination,
    SearchAgency,
    EditApiInfo: () => import('@/views/agents/agencies/EditApiInfo')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowEditModal: false,
      editingId: 0,
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },

    columns() {
      return [
        {
          title: '企业用户',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          width: 250,
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '账号状态',
          dataIndex: 'effective'
        },
        {
          title: '联系人',
          dataIndex: 'contact'
        },
        {
          title: '产品数量',
          dataIndex: 'products_count'
        },
        {
          title: '卡号总数',
          dataIndex: 'card_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'card_count' ? this.sort.sort_order : false
        },
        {
          title: '本月周期到期卡数',
          dataIndex: 'current_month_expire_card_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'current_month_expire_card_count' ? this.sort.sort_order : false
        },
        {
          title: '本月预计销卡数',
          dataIndex: 'current_month_expect_cancel_card_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'current_month_expect_cancel_card_count' ? this.sort.sort_order : false
        },
        {
          title: 'API权限',
          dataIndex: 'is_api_enabled_display',
          scopedSlots: { customRender: 'is_api_enabled_display' }
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    handleShowApiDetail(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgencies(this.agentId, Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination.total_count = res.pagination.total_count
        }
        this.loading = false
      })
    },

    exportData() {
      exportAgencies(this.agentId, Object.assign({}, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download === true) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
